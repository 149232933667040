import { render, staticRenderFns } from "./HomeSliderBlogPost.vue?vue&type=template&id=21141ad4"
import script from "./HomeSliderBlogPost.vue?vue&type=script&lang=js"
export * from "./HomeSliderBlogPost.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CornerLink: require('/vercel/path0/components/shared/CornerLink.vue').default,BlogCard: require('/vercel/path0/components/shared/BlogCard.vue').default,ScrollDownButton: require('/vercel/path0/components/shared/home/ScrollDownButton.vue').default})
