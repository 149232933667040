
export default {
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      options: {
        loop: true,
        grabCursor: true,
        navigation: false,

        autoplay: {
          delay: 7.5 * 1000, // 7.5 seconds
        },

        speed: 750,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
}
