import { render, staticRenderFns } from "./HomeSlider.vue?vue&type=template&id=7af69d82"
import script from "./HomeSlider.vue?vue&type=script&lang=js"
export * from "./HomeSlider.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeSliderImage: require('/vercel/path0/components/shared/home/HomeSliderImage.vue').default,HomeSliderProduct: require('/vercel/path0/components/shared/home/HomeSliderProduct.vue').default,HomeSliderBlogPost: require('/vercel/path0/components/shared/home/HomeSliderBlogPost.vue').default})
