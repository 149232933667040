export default {
  computed: {
    productNumber() {
      if (this.product.article_nr && this.product.product_nr) {
        return `Art. ${this.product.article_nr} <span class="px-1">|</span> Pr. ${this.product.product_nr}`
      }

      if (this.product.article_nr) {
        return `Art. ${this.product.article_nr}`
      }

      return `Pr. ${this.product.product_nr}`
    },
  },
}
