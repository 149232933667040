export default {
  methods: {
    currency(amount) {
      if (!amount) {
        amount = '0.00'
      }

      const options = { style: 'currency', currency: 'CHF' }

      return new Intl.NumberFormat('de-CH', options).format(amount)
    },

    currencyShort(amount) {
      if (!amount) {
        return '0.00'
      }

      return this.currency(amount).slice(4)
    },
  },
}
