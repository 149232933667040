
import Currency from '~/mixins/Currency.js'
import ProductNumber from '~/mixins/ProductNumber.js'

export default {
  mixins: [Currency, ProductNumber],

  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },

    product: {
      type: Object,
      required: true,
    },
  },

  computed: {
    productPrice() {
      return this.$auth.$state.priceInclTax
        ? this.product.gross_price
        : this.product.price
    },
  },
}
