import { render, staticRenderFns } from "./HomeSliderProduct.vue?vue&type=template&id=a23e316a"
import script from "./HomeSliderProduct.vue?vue&type=script&lang=js"
export * from "./HomeSliderProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/vercel/path0/components/shared/ProductCard.vue').default,CornerLink: require('/vercel/path0/components/shared/CornerLink.vue').default,ProductWideCard: require('/vercel/path0/components/shared/ProductWideCard.vue').default,ScrollDownButton: require('/vercel/path0/components/shared/home/ScrollDownButton.vue').default})
